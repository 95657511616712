import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p id='logo' title="01001011">
        01001011
        </p>
        <p id='sub_title'>A Technology Company!</p> <br></br>
        <a href='mailto:superkarthi789@gmail.com' id='enquiry'>Enquire ✉️</a>
      </header>
    </div>
  );
}

export default App;
